import React, {Component} from 'react'
import {connect} from 'react-redux'
import {mapStorageDispatchToProps, mapStorageToProps} from '../../../redux/mappers'
import {Col, FormFeedback, FormGroup, Input, Label} from 'reactstrap'

import parse from 'html-react-parser'
import * as PropTypes from 'prop-types'

import {getValueFromFormOrDefault} from './FormUtils'
import {getMsg, isValid} from '../Validation'

class FormCheckbox extends Component {
    static defaultProps = {
        colLg: 6
    }

    render() {
        let props = this.props
        let invalid = isValid(props.field)

        return <>
            <div className={'mt-2 col-md-' + props.colLg}>
                <FormGroup row>
                    <Label for={props.formId + '.' + props.field} className='text-md-right'
                           sm={3}>{props.label}{props.required &&
                        <span className='fred pl-1'>*</span>}</Label>
                    <Col sm={{size: 9}} className='my-auto'>
                        <FormGroup check>
                            <Label check>
                                <Input type='checkbox' name={props.field} id={props.formId + '.' + props.field}
                                       invalid={true}
                                       onChange={(e) => {
                                           e.persist()
                                           props.setValueOnForm(props.formId, props.field, e.target.checked)
                                       }}
                                       checked={getValueFromFormOrDefault(props.formId, props.field, false)}
                                       disabled={props.disabled}
                                />
                                {parse(props.labelRight.replaceAll('\n', '<br/>'))} &nbsp;
                                {invalid && <FormFeedback style={{
                                    display: 'block',
                                }}>{getMsg(props.field)}</FormFeedback>}
                            </Label>
                        </FormGroup>
                    </Col>
                </FormGroup>
            </div>
        </>
    }
}

FormCheckbox.defaultProps = {
    colLg: 6,
    disabled: false,
    required: false
}

FormCheckbox.propTypes = {
    colLg: PropTypes.number,
    disabled: PropTypes.bool,
    field: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelRight: PropTypes.string,
    required: PropTypes.bool
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(FormCheckbox)
