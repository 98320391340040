import React, {Component} from 'react'
import {Translation} from 'react-i18next'
import {connect} from 'react-redux'
import {dismissToast, store} from 'app/redux/redux'
import {mapStorageDispatchToProps, mapStorageToProps} from 'app/redux/mappers'
import {Toast, ToastBody, ToastHeader} from 'reactstrap'

import Logger from 'app/Logger'

class Toasts extends Component {
    toggleAlert = (toast) => {
        return () => {
            Logger.debug(toast)
            store.dispatch(dismissToast(toast))
        }
    }

    render() {
        return <Translation>{(t) =>
            <div style={{top: 60, right: 5, position: 'fixed', zIndex: 1000000}}>
                <div style={{overflow: 'visible'}}>
                    {this.props.toast.map((toast, key) => {
                        return <Toast key={key}>
                            <ToastHeader icon={toast.type === 'e' ? 'danger' : 'success'}
                                         toggle={this.toggleAlert(toast)}>
                                {toast.type === 'e' ? t('toasts.error') : t('toasts.info')}
                            </ToastHeader>
                            <ToastBody>
                                {t(toast.msg)}
                            </ToastBody>
                        </Toast>
                    })}
                </div>
            </div>
        }</Translation>
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(Toasts)
