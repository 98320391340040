import React, {Component} from 'react'
import {connect} from 'react-redux'
import {mapStorageDispatchToProps, mapStorageToProps} from '../../../redux/mappers'
import {Col, FormGroup, Input, Label} from 'reactstrap'

import * as PropTypes from 'prop-types'

class FormCheckboxSimple extends Component {
    static defaultProps = {
        colLg: 6
    }

    render() {
        return <>
            <div className={'mt-2 col-md-6 col-sm-12'}>
                <FormGroup row>
                    <Label className='text-md-right' xs={6} sm={3} md={6} lg={6}>
                        {this.props.label}{this.props.required && <span className='fred pl-1'>*</span>}</Label>
                    <Col xs={2} sm={2} md={2} lg={4}>
                        <FormGroup check>
                            <Label check>
                                <Input type='checkbox' name={this.props.name} id={this.props.name}
                                       invalid={true}
                                       onChange={(e) => {
                                           e.persist()
                                           this.props.callback(e.target.checked)
                                       }}
                                       checked={this.props.checked}
                                       disabled={this.props.disabled}

                                />
                                {this.props.labelRight} &nbsp;
                            </Label>
                        </FormGroup>
                    </Col>
                </FormGroup>
            </div>
        </>
    }
}

FormCheckboxSimple.defaultProps = {
    colLg: 6,
    disabled: false,
    required: false
}

FormCheckboxSimple.propTypes = {
    colLg: PropTypes.number,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelRight: PropTypes.string,
    required: PropTypes.bool,
    callback: PropTypes.func,
    checked: PropTypes.bool
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(FormCheckboxSimple)
