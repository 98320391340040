import {Api} from './Api'

export class ConsentApi extends Api {
    static apiUrl = window.var.API_HOST + '/api/v1/patient/consent'

    static checkCompletenessOfConsents() {
        return ConsentApi.get('/required/accept')
    }

    static getNotCheckedConsents() {
        return ConsentApi.get('/notaccepted')
    }

    static acceptConsent(id) {
        return ConsentApi.get('/accept/' + id)
    }

    static deleteAcceptConsent(id) {
        return ConsentApi.delete('/accept/' + id)
    }
}
