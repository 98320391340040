import React from 'react'

import AppContent from './AppContent'
import AppFooter from './AppFooter'
import AppSidebar from './AppSidebar'
import AppTopbar from './AppTopbar'
import {Localization} from "react-widgets/esm";
import {DateLocalizer} from "react-widgets/IntlLocalizer";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import AppContentAnonymous from "./AppContentAnonymous";

export function AppWrapper() {

    const {t} = useTranslation()

    return (
        <>
            <Localization
                date={new DateLocalizer({
                    firstOfWeek: 1,
                    culture: i18n.resolvedLanguage === 'ua' ? "uk" : i18n.resolvedLanguage
                })}
                messages={{
                    moveToday: t('reactWidgets.moveToday'),
                    moveBack: t('reactWidgets.moveBack'),
                    moveForward: t('reactWidgets.moveForward'),
                    emptyList: t('reactWidgets.emptyList'),
                    emptyFilter: t('reactWidgets.emptyFilter')
                }}
            >
                <AppTopbar/>

                <div id="wrapper">
                    <AppSidebar/>
                    <AppContent/>
                    <div id="modal-root"></div>
                </div>

                <AppFooter/>
            </Localization>
        </>
    );
}
