import {lazy} from 'react'

const ViewVisits = lazy(() => import('../app-views/authorized/patient/ViewVisits'))
const ViewDiseases = lazy(() => import('../app-views/authorized/patient/ViewDiseases'))
const ViewMyData = lazy(() => import('../app-views/authorized/patient/ViewMyData'))
const VerifyEmail = lazy(() => import('../app-views/authorized/patient/VerifyEmail'))
const ViewVideochat = lazy(() => import('../app-views/authorized/patient/ViewVideochat'))
const CovidQuest = lazy(() => import('../app-views/authorized/patient/CovidQuest'))
const ViewSurvey = lazy(() => import('../app-views/authorized/patient/ViewSurvey'))
const ViewSurveys = lazy(() => import('../app-views/authorized/patient/ViewSurveys'))

export const routesPatient = (props) => {

    return [{
        key: 'home',
        exact: true,
        path: '/',
        has: 'patient',
        component: ViewVisits,
        sidebar: true,
        label: 'routesPatient.visits',
        icon: 'fas fa-calendar-alt',
        featureFlagsShow: true
    },
        {
            key: 'comorbidities',
            exact: true,
            path: '/choroby',
            has: 'patient',
            component: ViewDiseases,
            sidebar: true,
            label: 'routesPatient.diseasesTreated',
            icon: 'fas fa-disease',
            featureFlagsShow: true
        },
        {
            key: 'myData',
            exact: true,
            path: '/mojedane',
            has: 'patient',
            component: ViewMyData,
            sidebar: true,
            label: 'routesPatient.myData',
            icon: 'fas fa-user-edit',
            featureFlagsShow: true
        },
        {
            key: 'verifyEmail',
            exact: true,
            has: 'patient',
            path: '/potwierdzemail',
            component: VerifyEmail,
            sidebar: false,
            featureFlagsShow: true
        },
        {
            key: 'Videochat',
            exact: true,
            has: 'patient',
            path: '/teleporada',
            component: ViewVideochat,
            sidebar: false,
            featureFlagsShow: true
        },
        {
            key: 'covidQuest',
            exact: true,
            path: '/ankieta-covid',
            has: 'patient',
            component: CovidQuest,
            sidebar: false,
            featureFlagsShow: true
        },
        {
            key: 'surveys',
            exact: true,
            path: '/surveys',
            has: 'patient',
            component: ViewSurveys,
            sidebar: true,
            label: 'routesPatient.surveys',
            icon: 'fas fa-list',
            featureFlagsShow: props.map(f => f.patientSurvey).includes(true)
        },
        {
            key: 'survey',
            exact: true,
            path: '/survey/:id',
            has: 'patient',
            component: ViewSurvey,
            sidebar: false,
            featureFlagsShow: props.map(f => f.patientSurvey).includes(true)
        }]
}

