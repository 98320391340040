import React, {Suspense, useEffect} from 'react'
import {connect} from 'react-redux'
import {mapStorageDispatchToProps, mapStorageToProps} from 'app/redux/mappers'
import {Switch, withRouter} from 'react-router-dom'

import {useErrorBoundary} from 'use-error-boundary'

import AppSecureRoute from './AppSecureRoute'

import Consents from '../app-components/common/consents/Consents'
import Loader from '../app-components/common/Loader'
import ErrorPage from '../app-components/common/ErrorPage'
import {routesPatient} from 'app/routes/routes-patient'
import {PatientApi} from "../api/PatientApi";

const AppContent = (props) => {
    const {
        ErrorBoundary,
        didCatch,
        error
    } = useErrorBoundary()

    useEffect(() => {
        PatientApi.getAllFeatureFlagForPatient().then(json => {
                console.log('tenants', json.data)
                props.setTenants(json.data)
            }
        )
    }, [props.user])

    const routes = routesPatient(props.tenants)

    return <>
        <div id='content-wrapper' className='py-3 px-3' style={{overflowX: 'initial'}}>
            <Consents/>
            {didCatch ? <ErrorPage error={error}/> :
                <ErrorBoundary>
                    <Suspense fallback={<Loader/>}>
                        <Switch>
                            {routes
                                .filter(r => r.featureFlagsShow)
                                .filter((r) => props.user.authorities && props.user.authorities.includes(r.has))
                                .map((r) => {
                                    return <AppSecureRoute id={r.path} {...r} />
                                })}
                        </Switch>
                    </Suspense>
                </ErrorBoundary>}
        </div>
    </>
}

export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(AppContent))
