import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Select, {components} from 'react-select'
import {Col, FormGroup, Label} from 'reactstrap'

import * as PropTypes from 'prop-types'

import Dicts from '../patient/Dicts'
import FlagIcon from './FlagIcon'

const SelectFlagIcon = (props) => {

    const {t} = useTranslation()
    const langs = props.languageList || Dicts.languageOriginalList()
    const {Option} = components;

    const getSelectedLang = (code) => {
        return langs.find(f => {
            return f.code === code
        })
    }

    const [selected, setSelected] = useState(getSelectedLang(props.initLangCode))

    useEffect(() => {
        setSelected(getSelectedLang(props.initLangCode))
    }, [props.initLangCode, props.languageList])

    const IconOption = (props) => (
        <Option {...props}>
            {props.data?.flag !== "" && <FlagIcon size={"lg"} code={props.data.flag} className={"mr-2"}/>}
            {props.data.label}
        </Option>
    );


    return <div className={"col-md-12 mt-2 col-lg-" + props.colLg}>
        <FormGroup row>
            <Label for={props.formId + '.' + props.field} sm={props.sizeLabel}
                   className="text-md-right text-label">{props.label}{props.required &&
                <span className="fred pl-1">*</span>}</Label>
            <Col sm={props.sizeCol} className="my-auto">
                <Select
                    isSearchable={false}
                    name={props.formId + '.' + props.field}
                    placeholder={t("select")}
                    onChange={(e) => {
                        props.setLangHandler(getSelectedLang(e.code))
                        setSelected(getSelectedLang(e.code))
                    }}
                    getOptionValue={(option) => option.code}
                    value={selected}
                    options={langs}
                    components={{Option: IconOption}}
                />

            </Col>
        </FormGroup>
    </div>
}


SelectFlagIcon.defaultProps = {
    colLg: 6,
    required: false,
    sizeLabel: 3,
    sizeCol: 9,
    selectOptions: true,
};

SelectFlagIcon.propTypes = {
    initLangCode: PropTypes.string,
    setLangHandler: PropTypes.func,
    colLg: PropTypes.number,
    label: PropTypes.string,
    required: PropTypes.bool,
    sizeLabel: PropTypes.number,
    sizeCol: PropTypes.number,

};

export default SelectFlagIcon
