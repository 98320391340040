import React from 'react'
import {store} from 'app/redux/redux'
import i18n from "i18next";

export const isValid = (field) => store.getState().validation.filter(f => f.field === field).length > 0

export const getMsg = (field) => store.getState().validation.filter(f => f.field === field).map((f, index) => {

    const msgArr = f.msg.split("|")
    const msg = msgArr[0]
    const values = msgArr.length==2 ? JSON.parse(msgArr[1]) : {}

    return <div key={field + index}>{i18n.t(msg, values)}</div>
})
