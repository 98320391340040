import React from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {mapStorageToProps} from 'app/redux/mappers'
import {Route, withRouter} from 'react-router-dom'
import {Alert} from 'reactstrap'

const AppSecureRoute = ({component: Component, props, ...rest}) => {
    const {t} = useTranslation()

    return (
        <>
            <Route {...rest} render={(props) => {
                return (
                    rest.user
                        ? <Component {...props} />
                        : <Alert color='warning'><i className='fas fa-exclamation-circle'/>{
                            t('appSecureRoute.insufficientPrivileges')
                        }</Alert>
                )
            }}/>
        </>
    )
}

export default withRouter(connect(mapStorageToProps)(AppSecureRoute))
