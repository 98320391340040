import {Api} from './Api'

export class PatientApi extends Api {
    static apiUrl = window.var.API_HOST + '/api/v1/patient/'

    static getPatient() {
        return PatientApi.get('account/')
    }

    static getAllPlannedVisits(criteria) {
        return PatientApi.post('schedule-visit/', criteria)
    }

    static cancelVisitWithReason(body) {
        return PatientApi.post('schedule-visit/cancel/', body)
    }

    static declineVisitProposal(visitId) {
        return PatientApi.get('schedule-visit/decline/' + visitId)
    }

    static confirmVisit(visitId) {
        return PatientApi.get('schedule-visit/confirm/' + visitId)
    }

    static getDiseases() {
        return PatientApi.get('account/diseases/')
    }

    static getAddresses() {
        return PatientApi.get('account/address/')
    }

    static getCommonPatientData() {
        return PatientApi.get('account')
    }

    static updateContactPatientData(contactData) {
        return PatientApi.patch('account/contact', contactData)
    }

    static updateAddress(address) {
        return PatientApi.patch('account/address', address)
    }

    static addAddress(address) {
        return PatientApi.post('account/address', address)
    }

    static deleteAddress(address) {
        return PatientApi.delete('account/address/' + address.id)
    }

    static getAuthorizedPersons() {
        return PatientApi.get('account/authorizedPersons')
    }

    static deleteAuthorizedPerson(person) {
        return PatientApi.delete('account/authorizedPersons/' + person.id)
    }

    static addAuthorizedPerson(person) {
        return PatientApi.post('account/authorizedPersons/', person)
    }

    static updateAuthorizedPerson(person) {
        return PatientApi.patch('account/authorizedPersons/', person)
    }

    static getPatientMedicalFacilities() {
        return PatientApi.get('account/clinic/')
    }

    static requestNewVisit(visit) {
        return PatientApi.post('schedule-visit/request', visit)
    }

    static addDiseaseDescription(payload) {
        return PatientApi.post('disease-description', payload)
    }

    static addPatientCovidQuest(payload) {
        return PatientApi.post('quest', payload)
    }

    static isCovidQuestAlreadyFilled(payload) {
        return PatientApi.post('quest/covid/is-filled', payload)
    }

    static getDiseaseDescription(visitId) {
        return PatientApi.get('disease-description/schedule-visit/' + visitId)
    }

    static getScheduleVisitDetails(scheduleVisitId) {
        return PatientApi.get('schedule-visit/' + scheduleVisitId)
    }

    static getUnfilledQuest(id) {
        return PatientApi.get('quest/unfilled/visit/' + id)
    }

    static getQrCode() {
        return PatientApi.get('account/qrcode')
    }

    static getApk() {
        return PatientApi.get('account/apk-patient')
    }

    static updatePassword(form) {
        return PatientApi.post('account/update/password', form)
    }

    static getConsentsNotRequired(criteria) {
        return PatientApi.post('consent/all/not-required', criteria)
    }

    static getConsentsRequiredAccepted(criteria) {
        return PatientApi.post('consent/all/required', criteria)
    }

    static getVersionApp() {
        return PatientApi.get('version')
    }

    static updatePatientPreferences(form) {
        return PatientApi.post("preferences/update", form)
    }

    static getPatientPreferences() {
        return PatientApi.get("preferences/get")
    }

    static getVisitFeatureFlags(scheduleVisitId) {
        return PatientApi.get("schedule-visit/feature-flags/" + scheduleVisitId)
    }

    static getAllFeatureFlagForPatient() {
        return PatientApi.get("account/feature-flags")
    }

    static getJaasJwt(roomName) {
        return PatientApi.get("schedule-visit/jass/" + roomName)
    }

    static getTenant(tenantId) {
        return PatientApi.get("tenant/" + tenantId)
    }
}
