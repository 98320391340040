import React from 'react'
import {store} from 'app/redux/redux'
import {setValidation} from '../../../redux/redux'

import i18n from 'i18next'
import _ from 'lodash'

export const cloneWithFormId = (children, formId) => {
    if (children instanceof Array) return _.map(children, (c, index) => {
        return React.cloneElement(c, {formId: formId, key: index})
    })
    return React.cloneElement(children, {formId: formId})
}

export const getValueFromFormOrDefault = (formId, field, defaultValue) => {
    let form = store.getState().form[formId]
    let value = field.split('.').reduce((res, curr) => res ? res[curr] : defaultValue, form)
    return value ? value : defaultValue
}

export const existValueOnForm = (formId, field) => {
    let form = store.getState().form[formId]
    let value = field.split('.').reduce((res, curr) => res ? res[curr] : undefined, form)
    return !(value === undefined || value === null)
}

export const getValueFromContextOrDefault = (field, defaultValue) => {
    let context = store.getState().context
    let value = field.split('.').reduce((res, curr) => res ? res[curr] : defaultValue, context)
    return value ? value : defaultValue
}

export const validateRequiredFields = (formId, fields) => {
    return () => {
        let errors = []
        _.map(fields, (field) => {
            if (!getValueFromFormOrDefault(formId, field, undefined)) {
                errors.push({field: field, msg: i18n.t('formUtils.fieldRequired')})
            }
        })
        if (errors.length > 0) {
            store.dispatch(setValidation(errors))
            return false
        }
        return true
    }
}
