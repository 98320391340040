import React, {Component} from 'react'

import * as PropTypes from 'prop-types'

import {cloneWithFormId} from './FormUtils'

export class FormSection extends Component {
    render() {
        return <>
            {this.props.upperHr && <hr/>}
            <div className='pb-3 bgr-white mx-1'>
                <div
                    className={'row bgr-white mx-1 pt-3 pb-2 pl-4 fselected fw900 ' + (this.props.title ? 'border-bottom' : '')}>
                    {this.props.foldable && <i className='fas fa-chevron-up'/>} <span
                    className={this.props.className ? this.props.className : 'h5 fw800'}>{this.props.title}</span>
                </div>
                {this.props.children ? cloneWithFormId(this.props.children, this.props.formId) : ''}
            </div>
        </>
    }
}

FormSection.defaultProps = {
    foldable: false
}

FormSection.propTypes = {
    children: PropTypes.node,
    title: PropTypes.any,
    className: PropTypes.any,
    foldable: PropTypes.bool,
    upperHr: PropTypes.bool
}
