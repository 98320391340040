import React from 'react'
import {Translation} from 'react-i18next'
import {connect} from 'react-redux'
import {mapStorageDispatchToProps, mapStorageToProps} from 'app/redux/mappers'
import {NavLink, withRouter} from 'react-router-dom'
import {Navbar} from 'reactstrap'
import {routesPatient} from 'app/routes/routes-patient'
import 'assets/css/sidebar.css'
import {AppMobileVersion} from "./AppMobileVersion"
import AppLanguage from "./AppLanguage"

class AppSidebar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            navToggled: false,
            getMobileAppModalOpen: false,
            langModalIsOpen: false
        }
    }

    toggle = () => {
        document.getElementsByTagName('body')[0].classList.toggle('sidebar-toggled')
        document.getElementsByClassName('sidebar')[0].classList.toggle('toggled')

        this.setState({
            navToggled: !this.state.navToggled
        })
    }

    logout = () => {
        this.props.clearUser()
        this.props.history.push('/')
        this.props.keycloak.logout()
    }

    closeSidebarOnMobile = () => {
        if (this.props.windowSize.width < 991) {
            this.props.closeSidebar()
        }
    }

    clearValidation = () => {
        this.props.clearValidation()
    }

    toggleGetMobileVersion = () => {
        this.setState({getMobileAppModalOpen: !this.state.getMobileAppModalOpen})
    }

    renderNavLink(item) {
        return <li className='nav-item sidebar-link'
                   role='presentation'
                   key={item.key}
                   onClick={this.closeSidebarOnMobile}>
            <NavLink className='nav-link' exact to={item.path} onClick={this.clearValidation}>
                <div className='row'>
                    <div className={this.state.navToggled ? 'col-12' : 'col-12 col-md-2'}>
                        <i className={item.icon + ' pl-2 pr-2'}/>
                    </div>
                    <div className={'label ' + (this.state.navToggled ? 'col-12' : 'col-12 col-md-10')}>
                        <Translation>{(t) => <span className='fw400'>{t(item.label)}</span>}</Translation>
                    </div>
                </div>
            </NavLink>
        </li>
    }

    render() {
        return (
            <>
                <AppMobileVersion isOpen={this.state.getMobileAppModalOpen}
                                  toggle={this.toggleGetMobileVersion}/>

                <Navbar
                    className={'navbar align-items-start sidebar accordion p-0 mt-1 sidebar-menu '
                        + (this.props.sidebar.sidebarOpen ? 'slide-in-left ' : 'slide-out-left ')}
                    style={Object.assign({}, (this.props.windowSize.width < 991 ? {
                        position: 'absolute', height: '100%'
                    } : {position: 'unset'}), {zIndex: 1001})}>
                    <AppLanguage isOpen={this.state.langModalIsOpen}
                                 toggle={() => this.setState({langModalIsOpen: false})}/>
                    <div style={{position: 'relative', height: '100%', width: '100%'}}>
                        <div onClick={this.toggle} className='d-flex align-items-center justify-content-center'
                             style={{
                                 position: 'absolute',
                                 right: -10,
                                 top: '50vh',
                                 width: 20,
                                 height: 40,
                                 backgroundColor: '#FFF',
                                 border: '2px solid #EFF5F6',
                                 borderRadius: '6px',
                                 zIndex: 1
                             }}>
                            <i className={'pointer text-secondary fas ' + (this.state.navToggled ? 'fa-chevron-right' : 'fa-chevron-left')}/>
                        </div>
                        <div className='container-fluid d-flex flex-column p-0'>
                            <ul className='nav navbar-nav' id='accordionSidebar'>
                                {routesPatient(this.props.tenants).filter((r) => r.sidebar)
                                    .filter((r) => this.props.user.authorities && this.props.user.authorities.includes(r.has))
                                    .filter(r => r.featureFlagsShow)
                                    .map((r) => this.renderNavLink(r))}
                                {this.props.tenants.map(f => f.mobileApp).includes(true) && this.mobileAppButton()}
                                {this.changeLangAppButton()}
                                {this.logoutButtonForMobile()}
                                <li className='nav-item sidebar-link fw700' role='presentation' key='comorbidities'>
                                    <hr/>
                                </li>
                                {/*{this.props.context.patient ? routesPersonnelPatient.filter((r) => r.sidebar)*/}
                                {/*    .filter((r) => this.props.user && this.props.user.authorities && this.props.user.authorities.includes(r.has))*/}
                                {/*    .map((r) => this.renderNavLink(r)) : ''}*/}
                            </ul>
                        </div>
                    </div>
                </Navbar>
            </>

        );
    }

    logoutButtonForMobile() {
        return <Translation>{(t) => <>
            {this.props.windowSize.width < 991 ?
                <li className='nav-item sidebar-link' role='presentation'
                    onClick={() => {
                        this.logout()
                    }}>
                    <span className='nav-link pointer'>
                        <div className='row'>
                            <div className='col-12 col-md-2'>
                                <i className='fas fa-sign-out pl-2 pr-2'/>
                            </div>
                            <div className='col-12 col-md-10'>
                                <span className='fw700'>{t('appSidebar.logout')}</span>
                            </div>
                        </div>
                    </span>
                </li>
                : null
            }
        </>}</Translation>
    }

    mobileAppButton() {
        return <Translation>{(t) => <>
            {this.props.windowSize.width < 991 ?
                <li className="nav-item sidebar-link" role="presentation" onClick={this.toggleGetMobileVersion}>
                        <span className='nav-link pointer'>
                            <div className='row text-gray-700'>
                                <div className='col-12 col-md-2'>
                                    <i className='fas fa-mobile pl-2 pr-2'/>
                                </div>
                                <div className='col-12 col-md-10'>
                                    <span className='fw700'>{t('appSidebar.mobileApp')}</span>
                                </div>
                            </div>
                        </span>
                </li>

                : null
            }
        </>}</Translation>
    }

    changeLangAppButton() {
        return <Translation>{(t) => <>
            {this.props.windowSize.width < 991 ?
                <li className="nav-item sidebar-link" role="presentation"
                    onClick={() => this.setState({langModalIsOpen: true})}>
                            <span className="nav-link pointer">
                                <div className="row text-gray-700">
                                    <div className="col-12 col-md-2">
                                        <i className="fas fa-mobile pl-2 pr-2"/>
                                    </div>
                                    <div className="col-12 col-md-10">
                                        <span className="fw700">{t("appSidebar.languages")}</span>
                                    </div>
                                </div>
                            </span>
                </li>
                :
                null
            }
        </>}</Translation>;
    }
}

export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(AppSidebar))
