import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

import LocalStorageBackend from 'i18next-localstorage-backend'
import i18nextDetector from 'i18next-browser-languagedetector'
import chainedBackend from 'i18next-chained-backend'
import i18nextBackend from 'i18next-http-backend'

import translationEN from '../locales/en.json'
import translationPL from '../locales/pl.json'
import translationUA from '../locales/ua.json'

const resources = {
    en: {
        translation: translationEN
    },
    pl: {
        translation: translationPL
    },
    ua: {
        translation: translationUA
    }
}

i18n
    .use(chainedBackend)
    .use(i18nextDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'pl',
        load: 'languageOnly',
        backend: {
            backends: [
                LocalStorageBackend,
                i18nextBackend
            ],
            backendOptions: [
                {expirationTime: 60}
            ]
        },
        react: {
            wait: false
        },
        interpolation: {
            escapeValue: false
        },
    })

export default i18n
