import React, {useState} from 'react'
import {Image} from 'react-bootstrap'
import {Translation} from 'react-i18next'
import {connect} from 'react-redux'
import {mapStorageDispatchToProps, mapStorageToProps} from 'app/redux/mappers'
import {NavLink, withRouter} from 'react-router-dom'
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar} from 'reactstrap'
import {AppMobileVersion} from "./AppMobileVersion"
import i18n from 'i18next'
import logo from '../../assets/img/topbar/logo.png'
import {PatientApi} from '../api/PatientApi'
import AppLanguage from "./AppLanguage";

const AppTopbar = (props) => {

    const [dropDownOpen, setDropDownOpen] = useState(false)
    const [langModalIsOpen, setLangModalIsOpen] = useState(false)
    const [getMobileAppModalOpen, setGetMobileAppModalOpen] = useState(false)

    const getQrCodePdf = () => {
        let newTab = window.open()

        PatientApi.getQrCode().then(json => {
            newTab.document.body.innerHTML = "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                encodeURI(json.data.base64) + "'></iframe>"
        })
    }

    const logout = () => {
        props.clearUser()
        props.history.push('/')
        props.keycloak.logout()
    }

    const toggleSidebar = () => {
        props.toggleSidebar()
    }

    const toggle = () => {
        setDropDownOpen(!dropDownOpen)
    }

    const toggleGetMobileVersion = () => {
        setGetMobileAppModalOpen(!getMobileAppModalOpen)
    }

    return <Translation>{(t) => <>

        <AppMobileVersion isOpen={getMobileAppModalOpen}
                          toggle={toggleGetMobileVersion}/>

        <AppLanguage isOpen={langModalIsOpen}
                     toggle={() => setLangModalIsOpen(false)}/>
        <Navbar color='light'
                light
                expand
                className='bg-white shadow-sm topbar static-top fw600 fblack py-0'
                style={{height: 60}}>
            {props.windowSize.width < 991 &&
                <>
                    <div style={{width: '20%', height: '100%'}} className='d-flex align-items-center'
                         onClick={toggleSidebar}>
                        <i className='fas fa-bars fa-lg pl-2' style={{verticalAlign: 'middle'}}/>
                    </div>
                    <Nav className='mr-auto' navbar></Nav>
                </>
            }
            <div>
                <Image className='align-items-center d-inline mr-4' src={logo} style={{height: '40px'}}/>
                {t('appTopbar.patientPortal')}
            </div>
            {props.windowSize.width > 991 ?
                <>
                    <Nav className='mr-auto' navbar></Nav>

                    <Dropdown isOpen={dropDownOpen} className='' toggle={toggle}>
                            <span className='p-0 pr-3 pl-3 h-100 align-items-center d-flex'>
                                {
                                    props.user.userInfo &&
                                    props.user.userInfo.personalData.firstname + ' ' +
                                    props.user.userInfo.personalData.lastname
                                }
                                <DropdownToggle className='pointer' tag='span'>
                                    <i className='fal fa-user fa-2x ml-4 mr-2 fwhite circle-icon'/>
                                    {dropDownOpen ?
                                        <i className='fal fa-chevron-up'/> :
                                        <i className='fal fa-chevron-down'/>}
                                </DropdownToggle>
                            </span>

                        <DropdownMenu className='mt-3 text-gray-400'>
                            <DropdownItem className='py-2'>
                                <NavLink exact to='/mojedane' onClick={props.clearValidation}>
                                    <div className='row text-gray-700'>
                                        <div className='col-3 text-center'>
                                            <i className='far fa-user-edit fa-lg'/>
                                        </div>
                                        <div className='col-9'>{t('appTopbar.myData')}</div>
                                    </div>
                                </NavLink>
                            </DropdownItem>

                            {props.tenants.map(f => f.mobileApp).includes(true) && <DropdownItem className='py-2' onClick={toggleGetMobileVersion}>
                                <div className='row text-gray-700'>
                                    <div className='col-3 text-center'>
                                        <i className='far fa-mobile fa-lg'/>
                                    </div>
                                    <div className='col-9'>{t('appTopbar.mobileApp')}</div>
                                </div>
                                {/*to download apk from google play*/}
                                {/*<Link style={{textDecoration: 'none'}} to={{ pathname: 'https://play.google.com/store/apps/details?id=com.whsmobile' }} target='_blank' >*/}
                                {/*    <div className='row text-gray-700'>*/}
                                {/*        <div className='col-3 text-center'>*/}
                                {/*            <i className='far fa-mobile fa-lg'/>*/}
                                {/*        </div>*/}
                                {/*        <div className='col-9'>{t('appTopbar.mobileApp')}</div>*/}
                                {/*    </div>*/}
                                {/*</Link>*/}
                            </DropdownItem>}

                            {props.tenants.map(f => f.mobileApp).includes(true) &&
                                <DropdownItem className='py-2' onClick={getQrCodePdf}>
                                    <div className='row text-gray-700'>
                                        <div className='col-3 text-center'><i className='far fa-qrcode fa-lg'/></div>
                                        <div className='col-9'>{t('appTopbar.qrCode')}</div>
                                    </div>
                                </DropdownItem>}

                            <DropdownItem className='py-2' onClick={() => setLangModalIsOpen(true)}>
                                <div className='row text-gray-700'>
                                    <div className='col-3 text-center'>
                                        {i18n.resolvedLanguage.toUpperCase()}
                                    </div>
                                    <div className='col-9'>{t('appTopbar.languages')}</div>
                                </div>
                            </DropdownItem>

                            <DropdownItem className='py-2' onClick={logout}>
                                <div className='row text-gray-700'>
                                    <div className='col-3 text-center'>
                                        <i className='far fa-sign-out-alt fa-lg'/>
                                    </div>
                                    <div className='col-9'>{t('appTopbar.logout')}</div>
                                </div>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </> : null}
        </Navbar>
    </>}</Translation>

}

export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(AppTopbar))
