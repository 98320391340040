import {
    ajaxStart,
    ajaxStop,
    clearContext,
    clearFeatureFlags,
    clearPreferences,
    clearSelectedValidation,
    clearSubcontext,
    clearTenants,
    clearUser,
    clearValidation,
    closeSidebar,
    dismissToast,
    openSidebar,
    pushToast,
    setContext,
    setDataTable,
    setDataTableParams,
    setFeatureFlags,
    setForm,
    setKeycloak,
    setLang,
    setPreferences,
    setTenants,
    setUser,
    setValidation,
    setValueOnForm,
    setWindowSize,
    toggleSidebar,
    unsetDataTable,
    unsetForm,
    updateDataTable
} from './redux'

export const mapStorageToProps = state => ({
    user: state.user,
    featureFlags: state.featureFlags,
    tenants: state.tenants,
    lang: state.lang,
    toast: state.toast,
    loading: state.loading,
    validation: state.validation,
    dataTable: state.dataTable,
    form: state.form,
    windowSize: state.windowSize,
    sidebar: state.sidebar,
    context: state.context,
    keycloak: state.keycloak,
    preferences: state.preferences
})

export const mapStorageDispatchToProps = {
    setLang,
    setUser,
    setFeatureFlags,
    setTenants,
    clearUser,
    pushToast,
    dismissToast,
    ajaxStart,
    ajaxStop,
    setValidation,
    clearValidation,
    clearSelectedValidation,
    setDataTable,
    updateDataTable,
    setDataTableParams,
    unsetDataTable,
    setValueOnForm,
    setForm,
    unsetForm,
    setWindowSize,
    toggleSidebar,
    openSidebar,
    closeSidebar,
    clearContext,
    clearSubcontext,
    clearTenants,
    clearFeatureFlags,
    setContext,
    setKeycloak,
    setPreferences,
    clearPreferences
}
