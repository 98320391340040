import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {FormRow} from '../app-components/common/Form/FormRow'
import {FormSection} from '../app-components/common/Form/FormSection'
import FormWrapper from '../app-components/common/Form/FormWrapper'
import AppModal from './AppModal'
import SelectFlagIcon from '../app-components/flag/SelectFlagIcon'
import i18n from "i18next"
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import {mapStorageDispatchToProps, mapStorageToProps} from "../redux/mappers"
import {PatientApi} from "../api/PatientApi"

const FORM_ID = 'languages'

const AppLanguage = (props) => {

    const {t} = useTranslation()

    useEffect(() => {

        if (props.isOpen) {
            PatientApi.getPatientPreferences().then(json => {

                let webAppLang = json.data?.lang?.webApp

                let preferences = {
                    ...json.data,
                    lang: {...json.data?.lang, webApp: webAppLang !== undefined ? webAppLang : i18n.resolvedLanguage}
                }

                props.setPreferences(preferences)
            })

        }

    }, [props.isOpen]);

    const saveLanguages = () => {


        PatientApi.updatePatientPreferences(props.preferences).then(json => {
            if (json.meta.status === "SUCCESS") {
                i18n.changeLanguage(json.data.lang.webApp).then(_ =>
                    console.log("language was changed")
                );
                props.clearValidation();
                props.toggle()
                PatientApi.getPatientPreferences().then(json => {
                    props.setPreferences(json.data)
                })

            } else {
                props.setValidation(json.meta.validation)
            }
        })
    }

    return <AppModal isOpen={props.isOpen} saveText={t('appLanguage.saveButton')}
                     toggle={props.toggle}
                     cancelIcon={"fas fa-times"}
                     saveIcon={"fas fa-check"}
                     saveAction={saveLanguages}>
        <FormWrapper formId={FORM_ID}>
            <FormSection>
                <FormRow>
                    <SelectFlagIcon required
                                    label={t('appLanguage.language')}
                                    initLangCode={props.preferences?.lang?.webApp}
                                    setLangHandler={(lang) => {
                                        let preferences = {
                                            ...props.preferences,
                                            lang: {...props.preferences?.lang, webApp: lang.code}
                                        }
                                        props.setPreferences(preferences)
                                    }}
                                    colLg={12}
                    />
                </FormRow>
            </FormSection>
        </FormWrapper>
    </AppModal>
}
export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(AppLanguage));
