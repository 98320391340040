import React, {Suspense} from 'react'
import {connect} from 'react-redux'
import {mapStorageDispatchToProps, mapStorageToProps} from '../redux/mappers'
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'

import {useErrorBoundary} from 'use-error-boundary'

import Loader from '../app-components/common/Loader'
import ErrorPage from '../app-components/common/ErrorPage'
import {routes} from '../routes/routes-annonymous'

const AppContentAnonymous = () => {
    const {
        ErrorBoundary,
        didCatch,
        error
    } = useErrorBoundary()

    return <>
        {didCatch ? <ErrorPage error={error}/> :
            <ErrorBoundary>
                <Suspense fallback={<Loader/>}>
                    <Switch>
                        {routes.map(r => {
                            return <Route {...r} />
                        })}
                        <Route path='/' render={() => (<Redirect to='/'/>)}/>
                    </Switch>
                </Suspense>
            </ErrorBoundary>
        }
    </>
}

export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(AppContentAnonymous))
