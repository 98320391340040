import React, {Component} from 'react'

import * as PropTypes from 'prop-types'

import {cloneWithFormId} from './FormUtils'

export class FormRow extends Component {
    render() {
        return <>
            <div className={'row bgr-white mx-1 ' + this.props.className}>
                {cloneWithFormId(this.props.children, this.props.formId)}
            </div>
        </>
    }
}

FormRow.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
}
