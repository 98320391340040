import React, {memo} from 'react'

const Loader = memo(() => {
    return (<div className='loader' style={{display: 'block'}}>
        <div className='loader-top'/>
        <div className='spinner-grow text-info spinner-lg' role='status'/>
    </div>)
})

export default Loader
