import React, {Component} from 'react'
import {Translation} from 'react-i18next'
import {connect} from 'react-redux'
import {mapStorageDispatchToProps, mapStorageToProps} from '../../../redux/mappers'
import {Form} from 'reactstrap'

import * as PropTypes from 'prop-types'

import {cloneWithFormId} from './FormUtils'

class FormWrapper extends Component {
    constructor(props) {
        super(props)

        this.save = this.save.bind(this)
    }

    save() {
        let validate = this.props.validate ? this.props.validate() : true
        if (validate) {
            this.props.save()
            this.props.clearValidation()
        }
    }

    render() {
        return <Translation>{(t) =>
            <div className='card mb-2'>
                <Form onSubmit={e => {
                    e.preventDefault()
                }}>
                    {cloneWithFormId(this.props.children, this.props.formId)}
                    <div className='row bgr-white mx-1 d-flex align-items-end justify-content-end'>
                        {this.props.save && <div className='btn btn-primary mt-4 mr-4 mb-4'
                                                 onClick={() => this.save()}>{t('formWrapper.saveButton')}</div>}
                    </div>
                </Form>
            </div>
        }</Translation>
    }
}

FormWrapper.propTypes = {
    children: PropTypes.node,
    save: PropTypes.func,
    validate: PropTypes.func
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(FormWrapper)
