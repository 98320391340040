import React, {Component} from 'react'

import moment from 'moment'

import {PatientApi} from '../api/PatientApi'

export default class AppFooter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            version: undefined,
            scalingTag: undefined
        }
    }

    componentDidMount() {
        PatientApi.getVersionApp().then((json) => {
            this.setState({version: json.data})
        })

        this.getScalingTag()
    }

    getScalingTag() {
        fetch(window.var.SCALING_HOST + '/tag')
            .then((res) => res.text())
            .then((data) => {
                this.setState({scalingTag: data})
            })
            .catch(reason => {
                this.setState({scalingTag: "brak"})
            })
    }

    render() {
        return <>
            <footer className='bg-white sticky-footer'>
                <div className='container my-auto'>
                    <div className='text-white small text-center'>
                        <span className='ml-2'>[{window.tag}]</span> <span>[{this.state.version}]</span>
                        <span>[{this.state.scalingTag}]</span>
                    </div>
                    <div className='text-center my-auto copyright'>
                        <span>Copyright © ITMatter {moment().format('Y')}</span>
                    </div>
                </div>
            </footer>
        </>
    }
}
