import React, {Component} from 'react'
import {Translation} from 'react-i18next'
import {connect} from 'react-redux'
import {mapStorageDispatchToProps, mapStorageToProps} from '../../../redux/mappers'

import _ from 'lodash'

import FormCheckbox from '../Form/FormCheckbox'
import FormCheckboxSimple from '../Form/FormCheckboxSimple'
import {FormSection} from '../Form/FormSection'
import {validateRequiredFields} from '../Form/FormUtils'
import FormWrapper from '../Form/FormWrapper'

import AppModal from '../../../app-main/AppModal'

import {ConsentApi} from '../../../api/ConsentApi'
import {PatientApi} from '../../../api/PatientApi'

const CONSENT_FORM_ID = 'consents'

class Consents extends Component {
    constructor(props) {
        super(props)

        this.state = {
            consentsCompleted: true,
            selectAllChecked: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let props = this.props
        let shouldSelectAllDisable = props.form[CONSENT_FORM_ID] &&
            _.reduce(_.map(props.form[CONSENT_FORM_ID].consentsToAccept, (consent, index) => {
                return nextProps.form[CONSENT_FORM_ID].consentsToAccept[index].checked
            }), (acc, consentState) => {
                return consentState && acc
            }, true)
        if (shouldSelectAllDisable !== undefined) {
            this.setState({selectAllChecked: shouldSelectAllDisable})
        }
    }

    componentDidMount() {
        this.checkConsents()
    }

    checkConsents = () => {
        ConsentApi.getNotCheckedConsents().then(json => {
                this.props.setValueOnForm(CONSENT_FORM_ID, 'consentsToAccept', json.data)
            }
        )
        ConsentApi.checkCompletenessOfConsents().then(json => {
            this.setState({consentsCompleted: json.data})
        })

        let props = this.props
        props.dataTable.allConsentsRequired_params && PatientApi.getConsentsRequiredAccepted(props.dataTable.allConsentsRequired_params)
            .then(json => {
                props.setDataTable(json.data, 'allConsentsRequired')
            })
        props.dataTable.allConsentsNotRequired_params && PatientApi.getConsentsNotRequired(props.dataTable.allConsentsNotRequired_params)
            .then(json => {
                props.setDataTable(json.data, 'allConsentsNotRequired')
            })
    }

    selectAll = (checked) => {
        if (checked) {
            this.setState({selectAllChecked: true})
            this.props.setValueOnForm(CONSENT_FORM_ID, 'consentsToAccept',
                _.map(this.props.form[CONSENT_FORM_ID].consentsToAccept, (consent, index) => {
                    let con = consent
                    con.checked = true
                    return con
                }))
        } else {
            this.setState({selectAllChecked: false})
            this.props.setValueOnForm(CONSENT_FORM_ID, 'consentsToAccept',
                _.map(this.props.form[CONSENT_FORM_ID].consentsToAccept, (consent, index) => {
                    let con = consent
                    con.checked = false
                    return con
                }))
        }
    }

    areRequiredChecked = () => {
        let notCheckedRequiredIndex = this.props.form[CONSENT_FORM_ID]?.consentsToAccept &&
            _.filter(this.props.form[CONSENT_FORM_ID].consentsToAccept, (it) => {
                return it.required && !(it.checked ? it.checked : false)
            })
        return validateRequiredFields(CONSENT_FORM_ID, _.map(_.keys(notCheckedRequiredIndex), (consent) => {
            return 'consentsToAccept.' + consent + '.checked'
        }))()
    }

    saveConsents = () => {
        let props = this.props

        let notCheckedRequiredIndex = props.form[CONSENT_FORM_ID]?.consentsToAccept &&
            _.keys(_.pickBy(props.form[CONSENT_FORM_ID].consentsToAccept, (it) => {
                return it.required && !(it.checked ? it.checked : false)
            }))
        let validation = validateRequiredFields(CONSENT_FORM_ID, _.map(notCheckedRequiredIndex, (consent) => {
            return 'consentsToAccept.' + consent + '.checked'
        }))()
        if (validation) {
            _.map(_.filter(props.form[CONSENT_FORM_ID].consentsToAccept, (it) => {
                return it.checked
            }), (consent) => {
                ConsentApi.acceptConsent(consent.id).then(this.checkConsents)
            })
        }
    }

    render() {
        return <Translation>{(t) => <>
            <AppModal className='modal-lg'
                      isOpen={!this.state.consentsCompleted}
                      cancelDisabled
                      dontCloseOnSave
                      saveAction={this.saveConsents}
                      title={t('consents.approvalOfConsentsTitle')}>
                <FormWrapper formId={CONSENT_FORM_ID}>
                    <FormSection borderBottom={false}>
                        <>
                            <FormCheckboxSimple name='checkAll'
                                                checked={this.state.selectAllChecked}
                                                callback={this.selectAll}
                                                label={t('consents.checkAll')}/>
                            {this.props.form[CONSENT_FORM_ID] &&
                                _.map(this.props.form[CONSENT_FORM_ID].consentsToAccept, (consent, index) => {
                                    return <div className={'mb-4'}>
                                        <FormCheckbox key={consent.id}
                                                      colLg={12}
                                                      required={consent.required}
                                                      label={consent.name}
                                                      labelRight={consent.content}
                                                      formId={CONSENT_FORM_ID}
                                                      field={'consentsToAccept.' + index + '.checked'}/>
                                    </div>
                                })}</>
                    </FormSection>
                </FormWrapper>
            </AppModal>
        </>}</Translation>
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(Consents)
