import React from 'react'
import {useTranslation} from 'react-i18next'

const ErrorPage = ({error}) => {
    const {t} = useTranslation()

    return <>
        {error.name === 'ChunkLoadError' ?
            <div className='jumbotron jumbotron-fluid'>
                <div className='container'>
                    <h1 className='display-4'>{t('errorPage.pleaseRefreshPage')}</h1>
                    <p className='lead'>{error.name}</p>
                </div>
            </div> : <div className='jumbotron jumbotron-fluid'>
                <div className='container'>
                    <h1 className='display-4'>{t('errorPage.somethingWentWrong')}</h1>
                    <p className='lead'>{error.name}</p>
                </div>
            </div>}
    </>
}

export default ErrorPage
