import React from 'react'
import {Translation} from 'react-i18next'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'

import * as PropTypes from 'prop-types'

export default class AppModal extends React.Component {
    constructor(props) {
        super(props)

        this.toggle = this.toggle.bind(this)
        this.save = this.save.bind(this)
    }

    toggle() {
        this.props.toggle()
    }

    save() {
        this.props.saveAction()
        if (!this.props.dontCloseOnSave) {
            this.props.toggle()
        }
    }

    render() {
        return <Translation>{(t) => <>
            <div>
                <Modal isOpen={this.props.isOpen} className={this.props.className}>
                    <ModalHeader>{t(this.props.title)}</ModalHeader>
                    <ModalBody>
                        {this.props.children}
                    </ModalBody>
                    <ModalFooter>
                        {this.props.additionalButtons ? this.props.additionalButtons : ''}
                        {this.props.saveAction ?
                            <button type='button' className={"btn btn-primary"} onClick={this.save}
                                    disabled={this.props.saveDisabled}>{t(this.props.saveText)}

                                {this.props.saveIcon && <i className={this.props.saveIcon + " ml-2"}/>}

                            </button> : ''}
                        {' '}
                        <button type='button' className={"btn btn-secondary"} onClick={this.toggle}
                                disabled={this.props.cancelDisabled}>{t(this.props.cancelText)}

                            {this.props.cancelIcon && <i className={this.props.cancelIcon + " ml-2"}/>}

                        </button>
                    </ModalFooter>
                </Modal>
            </div>
        </>}</Translation>
    }
}

AppModal.defaultProps = {
    cancelDisabled: false,
    cancelText: 'appModal.cancel',
    dontCloseOnSave: false,
    saveDisabled: false,
    saveText: 'appModal.save',
    title: 'appModal.title'
}

AppModal.propTypes = {
    additionalButtons: PropTypes.node,
    children: PropTypes.any,
    cancelDisabled: PropTypes.bool,
    cancelText: PropTypes.string,
    className: PropTypes.string,
    dontCloseOnSave: PropTypes.bool,
    isOpen: PropTypes.bool,
    saveAction: PropTypes.func,
    saveDisabled: PropTypes.bool,
    saveText: PropTypes.string,
    title: PropTypes.string,
    toggle: PropTypes.func
}
