import React, {Component} from 'react'
import {I18nextProvider} from 'react-i18next'
import {connect} from 'react-redux'
import {mapStorageDispatchToProps, mapStorageToProps} from 'app/redux/mappers'
import {BrowserRouter as Router} from 'react-router-dom'
import Dicts from "../app-components/patient/Dicts"
import i18n from '../i18n'
import Keycloak from 'keycloak-js'
import Toasts from '../app-components/common/Toasts'
import {AppWrapper} from 'app/app-main/AppWrapper'

import {PatientApi} from '../api/PatientApi'

class App extends Component {
    constructor(props) {
        super(props)

        this.state = {
            userLoaded: false,
            authenticated: false
        }
    }

    updateDimensions = () => {
        this.props.setWindowSize(window.innerWidth, window.innerHeight)
        if (window.innerWidth > 991) {
            this.props.openSidebar()
        } else {
            this.props.closeSidebar()
        }
    }

    componentDidMount() {
        const keycloak = Keycloak('/keycloak.json')

        keycloak.init({
            onLoad: 'login-required',
            // po zalogowaniu przekierowujemy na link z ktorym przyszedl pacjent
            redirectUri: window.location.href
        })
            .then(authenticated => {
                // czyscimy redirectUri zeby juz nie wyrocic na linka po wylogowaniu i zalogowaniu
                keycloak.redirectUri = window.var.WEB_HOST
                setInterval(() => keycloak.updateToken(60), 30)

                this.props.setKeycloak(keycloak)
                this.setState({authenticated: authenticated})

                let roles = keycloak.resourceAccess.core.roles

                if (!roles.includes('patient')) {
                    this.props.clearUser()
                    this.props.keycloak.logout()
                }

                keycloak.loadUserInfo().then(userInfo => {
                    PatientApi.getPatient().then(r => {
                        this.props.setUser({userInfo: {...userInfo, ...r.data}, authorities: roles})
                    })

                    PatientApi.getPatientPreferences().then(json => {
                        const lang = json.data?.lang?.webApp
                        const langExist = Dicts.languageList().find(f => {
                            return f.value === lang
                        })
                        if (lang !== undefined && langExist !== undefined) {
                            i18n.changeLanguage(lang).then(_ => console.log("language change to default preference"))
                        }
                    })

                    this.setState({userLoaded: true})
                })
            })

        this.updateDimensions()
        window.addEventListener('resize', this.updateDimensions)
    }

    render() {
        if (this.appNotReady()) {
            return this.getLoader(true)
        }

        return (
            <I18nextProvider i18n={i18n}>
                <div>
                    {this.getLoader(this.props.loading)}

                    <Router>
                        <AppWrapper/>
                    </Router>

                    {/*<DebugSidebar/>*/}
                    <Toasts/>
                </div>
            </I18nextProvider>
        )
    }

    appNotReady() {
        return !this.state.userLoaded
    }

    getLoader(isLoading) {
        return (
            <div className='loader' style={{display: isLoading ? 'block' : 'none'}}>
                <div className='loader-top'/>
                <div className='spinner-grow text-info spinner-lg' role='status'/>
            </div>
        )
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(App)
