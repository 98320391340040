import {combineReducers, createStore} from 'redux'
import moment from 'moment'
import Logger from 'app/Logger'

/**
 * Lang
 */
export const setLang = (lang) => ({
    type: 'SET_LANG',
    lang
})

export const lang = (state = {}, action) => {
    if (action.type === 'SET_LANG') {
        localStorage.setItem('lang', action.lang)
        return action.lang
    } else {
        return state
    }
}

/**
 * Keycloak
 */
export const setKeycloak = (keycloak) => ({
    type: 'SET_KEYCLOAK',
    keycloak
})

export const keycloak = (state = null, action) => {
    switch (action.type) {
        case 'SET_KEYCLOAK':
            return action.keycloak
        default:
            return state
    }
}

/**
 * User
 */
export const setUser = (user) => ({
    type: 'SET_USER',
    user
})

export const clearUser = () => ({
    type: 'CLEAR_USER'
})

export const user = (state = {}, action) => {
    switch (action.type) {
        case 'SET_USER':
            return action.user
        case 'CLEAR_USER':
            return {}
        default:
            return state
    }
}


/**
 * Tenant
 */
export const setTenants = (tenants) => ({
    type: 'SET_TENANTS',
    tenants
})

export const clearTenants = () => ({
    type: 'CLEAR_TENANTS'
})

export const tenants = (state = [], action) => {
    switch (action.type) {
        case 'SET_TENANTS':
            return action.tenants
        case 'CLEAR_TENANTS':
            return {}
        default:
            return state
    }
}

/**
 * Feture flags
 */
export const setFeatureFlags = (featureFlags) => ({
    type: 'SET_FEATURE_FLAGS',
    featureFlags
})

export const clearFeatureFlags = () => ({
    type: 'CLEAR_FEATURE_FLAGS'
})

export const featureFlags = (state = {}, action) => {
    switch (action.type) {
        case 'SET_FEATURE_FLAGS':
            return action.featureFlags
        case 'CLEAR_FEATURE_FLAGS':
            return {}
        default:
            return state
    }
}


/**
 * Validation
 */
export const setValidation = (validation) => ({
    type: 'SET_VALIDATION',
    validation
})

export const clearValidation = () => ({
    type: 'CLEAR_VALIDATION'
})

export const clearSelectedValidation = (fields) => ({
    type: 'CLEAR_SELECTED_VALIDATION',
    fields
})

export const validation = (state = [], action) => {
    switch (action.type) {
        case 'SET_VALIDATION':
            return action.validation
        case 'CLEAR_VALIDATION':
            return []
        case 'CLEAR_SELECTED_VALIDATION':
            return state.filter((v) => action.fields.indexOf(v.field) === -1)
        default:
            return state
    }
}

/**
 * Toasts
 */
export const pushToast = (t, msg) => {
    setTimeout(() => {
        store.dispatch(dismissToast(t))
    }, 10000)

    return ({
        type: 'PUSH_TOAST',
        t,
        msg
    })
}

export const dismissToast = (toast) => ({
    type: 'DISMISS_TOAST',
    state: store.getState().toast,
    t: toast
})

export const toast = (state = [], action) => {
    switch (action.type) {
        case 'PUSH_TOAST':
            state.unshift({timestamp: moment.now(), type: action.t, msg: action.msg})
            return state.splice(0, 5)
        case 'DISMISS_TOAST':
            let index = state.indexOf(action.t)
            state.splice(index, 1)
            return Object.assign([], state)
        default:
            return state
    }
}

/**
 * Loading
 */
export const ajaxStart = () => ({
    type: 'AJAX_START'
})

export const ajaxStop = () => ({
    type: 'AJAX_STOP'
})

export const loading = (state = 0, action) => {
    switch (action.type) {
        case 'AJAX_START':
            Logger.debug('start ajax')
            return state + 1
        case 'AJAX_STOP':
            Logger.debug('stop ajax')
            return state - 1
        default:
            return state
    }
}

/**
 * DataTables
 */
export const setDataTable = (dataTable, name) => ({
    type: 'SET_DATA_TABLE',
    dataTable,
    name
})

export const updateDataTable = (name) => ({
    type: 'UPDATE_DATA_TABLE',
    name
})

export const setDataTableParams = (params, name) => ({
    type: 'SET_DATA_TABLE_PARAMS',
    params,
    name
})

export const unsetDataTable = (name) => ({
    type: 'UNSET_DATA_TABLE',
    name
})

export const dataTable = (state = {}, action) => {
    switch (action.type) {
        case 'SET_DATA_TABLE':
            Logger.debug('SET_DATA_TABLE', action, state)
            return {
                ...state,
                [action.name]: {
                    rows: action.dataTable.rows,
                    total: action.dataTable.total
                }
            }
        case 'UPDATE_DATA_TABLE':
            Logger.debug('UPDATE_DATA_TABLE', action)
            return {
                ...state,
                [action.name]: {
                    ...state[action.name],
                    _rand: Math.random()
                }
            }
        case 'SET_DATA_TABLE_PARAMS':
            Logger.debug('SET_DATA_TABLE_PARAMS', action, state)
            return {
                ...state,
                [action.name + '_params']: action.params
            }
        case 'UNSET_DATA_TABLE':
            Logger.debug('UNSET_DATA_TABLE', action, state)
            return {
                ...state,
                [action.name]: undefined,
                [action.name + '_params']: undefined
            }
        default:
            return state
    }
}

/**
 * Window size change
 */
export const setWindowSize = (width, height) => ({
    type: 'SET_WINDOW_SIZE',
    width,
    height
})

export const windowSize = (state = {}, action) => {
    if (action.type === 'SET_WINDOW_SIZE') {
        return {
            width: action.width,
            height: action.height
        }
    } else {
        return state
    }
}

/**
 * Sidebar
 */
export const toggleSidebar = () => ({
    type: 'SET_SIDEBAR'
})

export const openSidebar = () => ({
    type: 'SET_SIDEBAR_OPEN'
})

export const closeSidebar = () => ({
    type: 'SET_SIDEBAR_CLOSE'
})

const initialState = {
    sidebarOpen: false
}

export const sidebar = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SIDEBAR':
            return {
                ...state,
                sidebarOpen: !state.sidebarOpen
            }
        case 'SET_SIDEBAR_OPEN':
            return {
                ...state,
                sidebarOpen: true
            }
        case 'SET_SIDEBAR_CLOSE':
            return {
                ...state,
                sidebarOpen: false
            }
        default:
            return state
    }
}

/**
 * Form
 */
export const setValueOnForm = (formId, field, value) => ({
    type: 'SET_VALUE_ON_FORM',
    formId,
    field,
    value
})

export const setForm = (formId, form) => ({
    type: 'SET_FORM',
    formId,
    form
})

export const unsetForm = (formId) => ({
    type: 'UNSET_FORM',
    formId
})

export const form = (state = {}, action) => {
    switch (action.type) {
        case 'SET_VALUE_ON_FORM':
            Logger.debug('SET_VALUE_ON_FORM', action, state)
            let stateX = {...state}
            if (!stateX[action.formId]) {
                stateX[action.formId] = {}
            }
            let current = stateX[action.formId]
            let keySplitted = action.field.split('.')
            keySplitted.forEach((key, index) => {
                if (index < keySplitted.length - 1) {
                    if (current[key] === undefined || current[key] === null) {
                        current[key] = {}
                    }
                    current = current[key]
                } else {
                    current[key] = action.value
                }
            })
            return stateX
        case 'SET_FORM':
            Logger.debug('SET_FORM', action, state)
            return {
                ...state,
                [action.formId]: action.form
            }
        case 'UNSET_FORM':
            Logger.debug('UNSET_FORM', action, state)
            return {
                ...state,
                [action.formId]: undefined
            }
        default:
            return state
    }
}

/**
 * Context
 */
export const setContext = (context) => ({
    type: 'SET_CONTEXT',
    context
})

export const clearContext = () => ({
    type: 'CLEAR_CONTEXT'
})

export const clearSubcontext = (contextName) => ({
    type: 'CLEAR_SUBCONTEXT',
    contextName
})

export const context = (state = {}, action) => {
    switch (action.type) {
        case 'SET_CONTEXT':
            return action.context
        case 'CLEAR_CONTEXT':
            return {}
        case 'CLEAR_SUBCONTEXT':
            return {
                ...state,
                [action.contextName]: undefined
            }
        default:
            return state
    }
}


/**
 * Preferences
 */

export const setPreferences = (preferences) => ({
    type: 'SET_PREFERENCES',
    preferences
});

export const clearPreferences = () => ({
    type: 'CLEAR_PREFERENCES'
});

export const preferences = (state = {}, action) => {

    switch (action.type) {
        case 'SET_PREFERENCES':
            return action.preferences;
        case 'CLEAR_PREFERENCES':
            return {};
        default:
            return state;
    }
};


export const reducers = combineReducers({
    user,
    featureFlags,
    tenants,
    lang,
    toast,
    loading,
    validation,
    dataTable,
    form,
    windowSize,
    sidebar,
    context,
    keycloak,
    preferences
})

export function configureStore(initialState = {}) {
    return window.var.DEV_MODE ? createStore(reducers,
            initialState,
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
        : createStore(reducers, initialState)
}

export const store = configureStore()
