import _ from 'lodash'
import i18n from 'i18next'

const Dicts = {
    addressType: function () {
        return [
            {label: i18n.t('dictAddressType.residence'), value: 'zamieszkania'},
            {label: i18n.t('dictAddressType.registered'), value: 'zameldowania'},
            {label: i18n.t('dictAddressType.correspondence'), value: 'korespondencyjny'}
        ]
    },

    decodeAddressType: function (key) {
        let value = _.first(_.filter(this.addressType(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    position: function () {
        return [
            {
                value: 'md',
                label: i18n.t('dictPosition.doctor')
            },
            {
                value: 'nurse',
                label: i18n.t('dictPosition.nurse')
            }
        ]
    },

    decodePosition: function (key) {
        let value = _.first(_.filter(this.position(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    sex: function () {
        return [
            {
                value: 'kobieta',
                label: i18n.t('dictSex.woman')
            },
            {
                value: 'mezczyzna',
                label: i18n.t('dictSex.man')
            },
            {
                value: 'nieokreslona',
                label: i18n.t('dictSex.undefined')
            }
        ]
    },

    decodeSex: function (key) {
        let value = _.first(_.filter(this.sex(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    visualClassif: function () {
        return [
            {
                value: 'surowiczy',
                label: i18n.t('dictVisualClassification.serous')
            },
            {
                value: 'surowiczny_krwisty',
                label: i18n.t('dictVisualClassification.serousBloody')
            },
            {
                value: 'krwisty',
                label: i18n.t('dictVisualClassification.bloody')
            },
            {
                value: 'surowiczo_ropny',
                label: i18n.t('dictVisualClassification.serousPurulent')
            },
            {
                value: 'wloknisty',
                label: i18n.t('dictVisualClassification.fibrous')
            },
            {
                value: 'ropny',
                label: i18n.t('dictVisualClassification.purulent')
            },
            {
                value: 'krwio_ropny',
                label: i18n.t('dictVisualClassification.bloodPurulent')
            },
            {
                value: 'krwotoczny',
                label: i18n.t('dictVisualClassification.hemorrhagic')
            }
        ]
    },

    decodeVisualClassif: function (key) {
        let value = _.first(_.filter(this.visualClassif(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    smellClassif: function () {
        return [
            {
                value: 'zapach_wyczuwalny_0',
                label: i18n.t('dictSmellClassification.noticeable0')
            },
            {
                value: 'zapach_wyczuwalny_1',
                label: i18n.t('dictSmellClassification.noticeable1')
            },
            {
                value: 'zapach_wyczuwalny_2',
                label: i18n.t('dictSmellClassification.noticeable2')
            },
            {
                value: 'zapach_wyczuwalny_3',
                label: i18n.t('dictSmellClassification.noticeable3')
            },
            {
                value: 'zapach_wyczuwalny_4',
                label: i18n.t('dictSmellClassification.noticeable4')
            },
            {
                value: 'brak_zapachu_5',
                label: i18n.t('dictSmellClassification.noticeable5')
            }
        ]
    },

    decodeSmellClassif: function (key) {
        let value = _.first(_.filter(this.smellClassif(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    pedisClassif: function () {
        return [
            {
                value: 'stopien_I',
                label: i18n.t('dictPedisClassification.degreeI')
            },
            {
                value: 'stopien_II',
                label: i18n.t('dictPedisClassification.degreeII')
            },
            {
                value: 'stopien_III',
                label: i18n.t('dictPedisClassification.degreeIII')
            },
            {
                value: 'stopien_IV',
                label: i18n.t('dictPedisClassification.degreeIV')
            }
        ]
    },

    decodePedisClassif: function (key) {
        let value = _.first(_.filter(this.pedisClassif(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    texasDepthClassif: function () {
        return [
            {
                value: 'stopien_0',
                label: i18n.t('dictTexasDepthClassification.degree0')
            },
            {
                value: 'stopien_1',
                label: i18n.t('dictTexasDepthClassification.degree1')
            },
            {
                value: 'stopien_2',
                label: i18n.t('dictTexasDepthClassification.degree2')
            },
            {
                value: 'stopien_3',
                label: i18n.t('dictTexasDepthClassification.degree3')
            }
        ]
    },

    decodeTexasDepthClassif: function (key) {
        let value = _.first(_.filter(this.texasDepthClassif(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    commentType: function () {
        return [
            {
                value: 'przed_planimetria',
                label: i18n.t('dictCommentType.beforePlanimetry')
            },
            {
                value: 'po_planimetri',
                label: i18n.t('dictCommentType.afterPlanimetry')
            }
        ]
    },

    decodeCommentType: function (key) {
        let value = _.first(_.filter(this.commentType(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    documentType: function () {
        return [
            {
                value: 'lekarza',
                label: i18n.t('dictDocumentType.doctor')
            },
            {
                value: 'pielegniarki',
                label: i18n.t('dictDocumentType.nurse')
            }
        ]
    },

    decodeDocumentType: function (key) {
        let value = _.first(_.filter(this.documentType(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    texasInfectionClassif: function () {
        return [
            {
                value: 'stopien_A',
                label: i18n.t('dictTexasInfectionClassification.degreeA')
            },
            {
                value: 'stopien_B',
                label: i18n.t('dictTexasInfectionClassification.degreeB')
            },
            {
                value: 'stopien_C',
                label: i18n.t('dictTexasInfectionClassification.degreeC')
            },
            {
                value: 'stopien_D',
                label: i18n.t('dictTexasInfectionClassification.degreeD')
            }
        ]
    },

    decodeTexasInfectionClassif: function (key) {
        let value = _.first(_.filter(this.texasInfectionClassif(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    visitType: function () {
        return [
            {
                value: 'wizyta_lekarska_zdalna',
                label: i18n.t('dictVisitType.remoteMedical')
            },
            {
                value: 'wizyta_pielegniarska_zdalna',
                label: i18n.t('dictVisitType.remoteNurse')
            },
            {
                value: 'wizyta_lekarska_miejscowa',
                label: i18n.t('dictVisitType.localMedical')
            },
            {
                value: 'wizyta_pielegniarska_miejscowa',
                label: i18n.t('dictVisitType.localNurse')
            },
            {
                value: 'wizyta_kontrolna',
                label: i18n.t('dictVisitType.controlVisit')
            }
        ]
    },

    visitTypeByProfession: function (profession) {
        if (profession === 'md') {
            return _.filter(this.visitType(), (t) => {
                return t.value.includes('lekarska') || t.value.includes('kontrolna')
            })
        } else if (profession === 'nurse') {
            return _.filter(this.visitType(), (t) => {
                return t.value.includes('pielegniarska') || t.value.includes('kontrolna')
            })
        }
        return this.visitType()
    },

    decodeVisitType: function (key) {
        let value = _.first(_.filter(this.visitType(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    visitKind: function () {
        return [
            {
                value: 'planowana',
                label: i18n.t('dictVisitKind.planned')
            },
            {
                value: 'nieplanowana',
                label: i18n.t('dictVisitKind.unplanned')
            }
        ]
    },

    decodeVisitKind: function (key) {
        let value = _.first(_.filter(this.visitKind(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    colorClassif: function () {
        return [
            {
                value: 'czarny',
                label: i18n.t('dictColorClassification.black')
            },
            {
                value: 'zolty',
                label: i18n.t('dictColorClassification.yellow')
            },
            {
                value: 'czerwony',
                label: i18n.t('dictColorClassification.red')
            },
            {
                value: 'rozowy',
                label: i18n.t('dictColorClassification.pink')
            }
        ]
    },

    decodeColorClassif: function (key) {
        let value = _.first(_.filter(this.colorClassif(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    microbiologyClassif: function () {
        return [
            {value: 'brak', label: i18n.t('dictMicrobiologyClassification.none')},
            {value: 'I', label: i18n.t('dictMicrobiologyClassification.I')},
            {value: 'II', label: i18n.t('dictMicrobiologyClassification.II')},
            {value: 'III', label: i18n.t('dictMicrobiologyClassification.III')}
        ]
    },

    decodeMicrobiologyClassif: function (key) {
        let value = _.first(_.filter(this.microbiologyClassif(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    allowedStatus: function () {
        return [
            {
                value: 'dopuszczony',
                label: i18n.t('dictAllowedStatus.admitted')
            },
            {
                value: 'niedopuszczony',
                label: i18n.t('dictAllowedStatus.notAdmitted')
            },
            {
                value: 'koniec_dopuszczenia_do_obrotu',
                label: i18n.t('dictAllowedStatus.endOfAdmit')
            }
        ]
    },

    decodeAllowedStatus: function (key) {
        let value = _.first(_.filter(this.allowedStatus(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    woundEtiology: function () {
        return [
            {value: 'zylna', label: i18n.t('dictWoundEtiology.żylna')},
            {value: 'tetnicza', label: i18n.t('dictWoundEtiology.tętnicza')},
            {value: 'mieszana', label: i18n.t('dictWoundEtiology.mieszana')},
            {value: 'nowotworcza', label: i18n.t('dictWoundEtiology.nowotwórcza')},
            {value: 'odlezynowaIstopnia', label: i18n.t('dictWoundEtiology.bedsoresDegreeI')},
            {value: 'odlezynowaIIstopnia', label: i18n.t('dictWoundEtiology.bedsoresDegreeII')},
            {value: 'odlezynowaIIIstopnia', label: i18n.t('dictWoundEtiology.bedsoresDegreeIII')},
            {value: 'odlezynowaIVstopnia', label: i18n.t('dictWoundEtiology.bedsoresDegreeIV')},
            {value: 'odlezynowaVstopnia', label: i18n.t('dictWoundEtiology.bedsoresDegreeV')},
            {value: 'pooperacyjna', label: i18n.t('dictWoundEtiology.postoperative')},
            {value: 'oparzeniowa', label: i18n.t('dictWoundEtiology.burns')},
            {value: 'pourazowa', label: i18n.t('dictWoundEtiology.traumatic')}
        ]
    },

    decodeWoundEtiology: function (key) {
        let value = _.first(_.filter(this.woundEtiology(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    stage: function () {
        return [
            {value: 'IA_1PL', label: i18n.t('dictStage.IA_1PL')},
            {value: 'IA_2PL', label: i18n.t('dictStage.IA_2PL')},
            {value: 'IA_3PL', label: i18n.t('dictStage.IA_3PL')},
            {value: 'IA_4PL', label: i18n.t('dictStage.IA_4PL')},
            {value: 'IA_1K', label: i18n.t('dictStage.IA_1K')},
            {value: 'IB_1PL', label: i18n.t('dictStage.IB_1PL')},
            {value: 'IB_2PL', label: i18n.t('dictStage.IB_2PL')},
            {value: 'IB_3PL', label: i18n.t('dictStage.IB_3PL')},
            {value: 'IB_1K', label: i18n.t('dictStage.IB_1K')},
            {value: 'IC_1PL', label: i18n.t('dictStage.IC_1PL')},
            {value: 'IC_2PL', label: i18n.t('dictStage.IC_2PL')},
            {value: 'IC_3PL', label: i18n.t('dictStage.IC_3PL')},
            {value: 'IC_4PL', label: i18n.t('dictStage.IC_4PL')},
            {value: 'IC_1K', label: i18n.t('dictStage.IC_1K')},
            {value: 'ID', label: i18n.t('dictStage.ID')},
            {value: 'II', label: i18n.t('dictStage.II')},
            {value: 'III_1P', label: i18n.t('dictStage.III_1P')},
            {value: 'III_2P', label: i18n.t('dictStage.III_2P')},
            {value: 'III_3P', label: i18n.t('dictStage.III_3P')},
            {value: 'III_4P', label: i18n.t('dictStage.III_4P')},
            {value: 'III_1K', label: i18n.t('dictStage.III_1K')}
        ]
    },

    decodeStage: function (key) {
        let value = _.first(_.filter(this.stage(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    mannequin: function () {
        return [
            {value: 'brzuch-lewo', label: i18n.t('dictMannequin.bellyLeft')},
            {value: 'brzuch-prawo', label: i18n.t('dictMannequin.bellyRight')},
            {value: 'glowa-przod', label: i18n.t('dictMannequin.headFront')},
            {value: 'glowa-tyl', label: i18n.t('dictMannequin.headBack')},
            {value: 'golen-przod-lewo', label: i18n.t('dictMannequin.leftShinFront')},
            {value: 'golen-przod-prawo', label: i18n.t('dictMannequin.rightShinFront')},
            {value: 'golen-tyl-lewo', label: i18n.t('dictMannequin.leftShinBack')},
            {value: 'golen-tyl-prawo', label: i18n.t('dictMannequin.rightShinBack')},
            {value: 'grzbiet-ledzwie-lewo', label: i18n.t('dictMannequin.backLoinLeftSide')},
            {value: 'grzbiet-ledzwie-prawo', label: i18n.t('dictMannequin.backLoinRightSide')},
            {value: 'grzbiet-lopatka-lewo', label: i18n.t('dictMannequin.leftScapulaRidge')},
            {value: 'grzbiet-lopatka-prawo', label: i18n.t('dictMannequin.rightScapulaRidge')},
            {value: 'klatka-piersiowa-lewo', label: i18n.t('dictMannequin.leftChest')},
            {value: 'klatka-piersiowa-prawo', label: i18n.t('dictMannequin.rightChest')},
            {value: 'kolano-lewo', label: i18n.t('dictMannequin.leftKnee')},
            {value: 'kolano-prawo', label: i18n.t('dictMannequin.rightKnee')},
            {value: 'lokiec-lewo', label: i18n.t('dictMannequin.leftElbow')},
            {value: 'lokiec-prawo', label: i18n.t('dictMannequin.rightElbow')},
            {value: 'posladek-lewo', label: i18n.t('dictMannequin.leftButtock')},
            {value: 'posladek-prawo', label: i18n.t('dictMannequin.rightButtock')},
            {value: 'przedramie-przod-lewo', label: i18n.t('dictMannequin.forearmLeftFront')},
            {value: 'przedramie-przod-prawo', label: i18n.t('dictMannequin.forearmRightFront')},
            {value: 'przedramie-tyl-lewo', label: i18n.t('dictMannequin.forearmLeftBack')},
            {value: 'przedramie-tyl-prawo', label: i18n.t('dictMannequin.forearmRightBack')},
            {value: 'ramie-przod-lewo', label: i18n.t('dictMannequin.leftArmFront')},
            {value: 'ramie-przod-prawo', label: i18n.t('dictMannequin.rightArmFront')},
            {value: 'ramie-tyl-lewo', label: i18n.t('dictMannequin.leftArmRear')},
            {value: 'ramie-tyl-prawo', label: i18n.t('dictMannequin.rightArmRear')},
            {value: 'reka-przod-lewo', label: i18n.t('dictMannequin.leftHandInside')},
            {value: 'reka-przod-prawo', label: i18n.t('dictMannequin.rightHandInside')},
            {value: 'reka-tyl-lewo', label: i18n.t('dictMannequin.leftHandOuterSide')},
            {value: 'reka-tyl-prawo', label: i18n.t('dictMannequin.rightHandOuterSide')},
            {value: 'stopa-przod-lewo', label: i18n.t('dictMannequin.leftFootForward')},
            {value: 'stopa-przod-prawo', label: i18n.t('dictMannequin.rightFootForward')},
            {value: 'stopa-tyl-lewo', label: i18n.t('dictMannequin.leftFootBack')},
            {value: 'stopa-tyl-prawo', label: i18n.t('dictMannequin.rightFootBack')},
            {value: 'szyja-przod', label: i18n.t('dictMannequin.neckFront')},
            {value: 'szyja-tyl', label: i18n.t('dictMannequin.neckBack')},
            {value: 'udo-przod-lewo', label: i18n.t('dictMannequin.leftThighFront')},
            {value: 'udo-przod-prawo', label: i18n.t('dictMannequin.rightThighFront')},
            {value: 'udo-tyl-lewo', label: i18n.t('dictMannequin.leftThighBack')},
            {value: 'udo-tyl-prawo', label: i18n.t('dictMannequin.rightThighBack')},
        ]
    },

    decodeMannequin: function (key) {
        let value = _.first(_.filter(this.mannequin(), (p) => {
            return p.value === key
        }))?.label || i18n.t('dictMannequin.notFound')
        return value ? value : ''
    },

    etiologiaRany: function () {
        return [
            {value: 'zylna', label: i18n.t('dictWoundEtiology.venous')},
            {value: 'tetnicza', label: i18n.t('dictWoundEtiology.arterial')},
            {value: 'mieszana', label: i18n.t('dictWoundEtiology.mixed')},
            {value: 'nowotworcza', label: i18n.t('dictWoundEtiology.neoplastic')},
            {value: 'odlezynowaIstopnia', label: i18n.t('dictWoundEtiology.bedsoresDegreeI')},
            {value: 'odlezynowaIIstopnia', label: i18n.t('dictWoundEtiology.bedsoresDegreeII')},
            {value: 'odlezynowaIIIstopnia', label: i18n.t('dictWoundEtiology.bedsoresDegreeIII')},
            {value: 'odlezynowaIVstopnia', label: i18n.t('dictWoundEtiology.bedsoresDegreeIV')},
            {value: 'odlezynowaVstopnia', label: i18n.t('dictWoundEtiology.bedsoresDegreeV')},
            {value: 'pooperacyjna', label: i18n.t('dictWoundEtiology.postoperative')},
            {value: 'oparzeniowa', label: i18n.t('dictWoundEtiology.burns')},
            {value: 'pourazowa', label: i18n.t('dictWoundEtiology.traumatic')},
            {value: 'cukrzycowa', label: i18n.t('dictWoundEtiology.diabetic')}
        ]
    },

    decodeEtiologiaRany: function (key) {
        let value = _.first(_.filter(this.etiologiaRany(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    visitStatus: function () {
        return [
            {value: 'potwierdzona', label: i18n.t('dictVisitStatus.confirmed')},
            {value: 'niepotwierdzona', label: i18n.t('dictVisitStatus.unconfirmed')},
            {value: 'odrzucona', label: i18n.t('dictVisitStatus.rejected')},
            {value: 'anulowana', label: i18n.t('dictVisitStatus.canceled')},
            {value: 'niezaplanowana', label: i18n.t('dictVisitStatus.unplanned')},
        ]
    },

    decodeVisitStatus: function (key) {
        let value = _.first(_.filter(this.visitStatus(), (p) => {
            return p.value === key
        }))
        return value ? value.label : ''
    },

    languageList: function () {
            return [
            {
                 flag: "pl",
                 code: "pl",
                 label: i18n.t('language.polish')
             },
             {
                 flag: "us",
                 code: 'en',
                 label: i18n.t('language.english')
             },
            {
                 flag: "ua",
                 code: "ua",
                 label: i18n.t('language.ukrainian')
             }
           ]
        },

    decodeLanguage: function (key) {
        let value = _.first(_.filter(this.languageList(), (p) => {
            return p.code === key
        }))
        return value ? value.label : ''
    },

    decodeLanguage: function (key) {
        let value = _.first(_.filter(this.languageOriginalList(), (p) => {
            return p.code === key
        }))
        return value ? value.moment : ''
    },

    languageOriginalList: function () {
            return [
            {
                 flag: "pl",
                 code: "pl",
                 moment: "pl",
                 label: "Polski"
             },
             {
                 flag: "us",
                 code: 'en',
                 moment: "en",
                 label: "English"
             },
            {
                 flag: "ua",
                 code: "ua",
                 moment: "uk",
                 label: "Український"
             }
           ]
        }
}

export default Dicts
